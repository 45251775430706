import {
  Box,
  Column,
  CurrencyInput,
  FormField,
  LinkButton,
  NumericInput,
  numericUtil,
  PercentInput,
  Row,
  Select,
} from '@cmg/common';
import {
  ioiTrackerAddIOISelector,
  ioiTrackerEditSelector,
  ioiTrackerFinalSharesSelector,
  ioiTrackerIndicatorsTableSelector,
  ioiTrackerLimitTypeWrapperSelector,
  ioiTrackerPassLabelSelector,
  ioiTrackerPassSelector,
  ioiTrackerRegMSelector,
} from '@cmg/e2e-selectors';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import TrashButton from '../../../../common/components/buttons/trash-button/TrashButton';
import { RadioGroup } from '../../../../obsolete/components/ui/inputs';
import { IoiLimitType, OfferingType } from '../../../../types/domain/offering/constants';
import { passTypeLabels } from '../../../../types/domain/offering/ioi/constants';
import RankingTag from '../../shared/components/RankingTag';
import { ioiLimitTypeOptions, ioiPricingTypeOptions, ioiUnitTypeOptions } from '../constants';
import AllocationConfirmDialog from './AllocationConfirmDialog';
import EditControls from './EditControls';
import {
  SButtonWrapper,
  SFieldValue,
  SFirmIndication,
  SIoiType,
  SLimitConfigContainer,
  SLimitType,
  SLimitValue,
  StyledRadioButton,
  StyledTable,
} from './FirmIndicationWidget.styles';
import FormControl from './FormControl';

export function styledConfig({ isInFormGroup }) {
  return {
    control: ({ menuIsOpen }, originalStyles) => ({
      borderBottomRightRadius:
        isInFormGroup || menuIsOpen ? 0 : originalStyles.borderBottomRightRadius,
      borderTopRightRadius: isInFormGroup ? 0 : originalStyles.borderTopRightRadius,
    }),
  };
}

/**
 * Needs refactor to move prop-types into typescript types
 */
export default class FirmIndicationWidget extends React.Component<any, any> {
  static propTypes = {
    canEdit: PropTypes.bool.isRequired,
    allocationEnabled: PropTypes.bool.isRequired,
    changeIoiType: PropTypes.func.isRequired,
    onClickEditButton: PropTypes.func.isRequired,
    onClickCancelButton: PropTypes.func.isRequired,
    onClickSaveButton: PropTypes.func.isRequired,
    onChangeIoIUnitType: PropTypes.func.isRequired,
    onChangeIoIDollars: PropTypes.func.isRequired,
    onChangeIoIPercentage: PropTypes.func.isRequired,
    onChangeIoIShares: PropTypes.func.isRequired,
    onChangeIoIPricingType: PropTypes.func.isRequired,
    onChangeIoILimitPrice: PropTypes.func.isRequired,
    onChangeIoIRealDemandDollars: PropTypes.func.isRequired,
    onChangeIoIRealDemandPercentage: PropTypes.func.isRequired,
    onChangeIoIRealDemandShares: PropTypes.func.isRequired,
    onClickDeleteIoIButton: PropTypes.func.isRequired,
    onClickAddIoIButton: PropTypes.func.isRequired,
    changeAllocation: PropTypes.func.isRequired,
    ioiType: PropTypes.string.isRequired,
    iois: PropTypes.array.isRequired,
    ioisFormErrors: PropTypes.object.isRequired,
    allocationShares: PropTypes.any,
    allocationRanking: PropTypes.string,
    allocationInvestment: PropTypes.string,
    isEditing: PropTypes.bool.isRequired,
    offeringType: PropTypes.string.isRequired,
    onChangeIoILimitType: PropTypes.func.isRequired,
    onChangeIoILimitPercentage: PropTypes.func.isRequired,
  };

  state = {
    showConfirmModal: false,
  };

  onChangeAllocationShares = value => {
    this.props.changeAllocation({ shares: value });
  };

  onChangeRanking = ranking => {
    const { allocationRanking } = this.props;
    const newRanking = allocationRanking === ranking ? '' : ranking;
    this.props.changeAllocation({ ranking: newRanking });
  };

  onConfirmAllocationDialog = () => {
    const { changeAllocation, onClickSaveButton, changeIoiType } = this.props;

    // @ts-ignore add futureIoiType to default state
    changeIoiType(this.state.futureIoiType);
    // shares should be a number | null
    changeAllocation({ shares: null, ranking: '' }, onClickSaveButton);
    this.setState({ showConfirmModal: false, futureIoiType: null });
  };

  onCancelAllocationDialog = () => {
    this.setState({
      showConfirmModal: false,
    });
  };

  onClickSelectIoIType = ioiType => {
    const { allocationShares, changeAllocation, onClickSaveButton, changeIoiType } = this.props;

    if (allocationShares) {
      this.setState({
        showConfirmModal: true,
        futureIoiType: ioiType,
      });
    } else {
      changeIoiType(ioiType);
      // shares should be a number | null
      changeAllocation({ shares: null, ranking: '' }, onClickSaveButton);
    }
  };

  render() {
    const { showConfirmModal } = this.state;
    const {
      canEdit,
      allocationEnabled,
      ioiType,
      iois,
      allocationShares,
      allocationRanking,
      allocationInvestment,
      ioisFormErrors,
      isEditing,
      onChangeIoIUnitType,
      onChangeIoIDollars,
      onChangeIoIPercentage,
      onChangeIoIShares,
      onChangeIoIPricingType,
      onChangeIoILimitPrice,
      onChangeIoIRealDemandDollars,
      onChangeIoIRealDemandPercentage,
      onChangeIoIRealDemandShares,
      onClickDeleteIoIButton,
      onClickAddIoIButton,
      onClickEditButton,
      onClickCancelButton,
      onClickSaveButton,
      offeringType,
      onChangeIoILimitType,
      onChangeIoILimitPercentage,
    } = this.props;

    return (
      <SFirmIndication data-testid="firm-indication">
        <Box>
          <div>
            <Box.Header title="Firm Indication">
              {canEdit && (
                <EditControls
                  isEditing={isEditing}
                  onClickEditButton={onClickEditButton}
                  onClickCancelButton={onClickCancelButton}
                  onClickSaveButton={onClickSaveButton}
                  testId={ioiTrackerEditSelector.testId}
                />
              )}
            </Box.Header>

            <Box.Content>
              {iois.length === 0 ? (
                <p style={{ marginBottom: 15 }}>No indications of interest have been entered.</p>
              ) : (
                <StyledTable
                  striped
                  isEditing={isEditing}
                  responsive={!isEditing}
                  testId={ioiTrackerIndicatorsTableSelector.testId}
                >
                  <thead>
                    <tr>
                      <th className="value-column">Limit Price (Order Type)</th>
                      <th className="value-column">Limit Configuration</th>
                      <th className="value-column">Unit (IoI Type)</th>
                      <th className="value-column">Quantity</th>
                      <th className="value-column">Internal Demand</th>
                      {isEditing && <th className="action-column" />}
                    </tr>
                  </thead>
                  <tbody>
                    {iois.map(ioi =>
                      !isEditing ? (
                        <tr key={ioi.uuid}>
                          <td className="value-column" col-id="firm-indication-order-type">
                            {ioi.pricingType === 'limit' && 'Limit'}
                            {ioi.pricingType === 'market' && 'At Market'}
                          </td>
                          <td className="value-column" col-id="firm-indication-limit">
                            {ioi.pricingType === 'limit' && (
                              <SLimitConfigContainer>
                                <SLimitType
                                  data-test-id={ioiTrackerLimitTypeWrapperSelector.testId}
                                >
                                  {ioi.limitType === IoiLimitType.PRICE && 'Price'}
                                  {ioi.limitType === IoiLimitType.DISCOUNT && 'Discount'}
                                  {ioi.limitType === IoiLimitType.PREMIUM && 'Premium'}
                                </SLimitType>
                                <SLimitValue>
                                  {ioi.limitType === IoiLimitType.PRICE
                                    ? numericUtil.formatCurrency(ioi.limitPrice)
                                    : numericUtil.formatPercents(ioi.limitPercentage, 2)}
                                </SLimitValue>
                              </SLimitConfigContainer>
                            )}
                          </td>
                          <td className="value-column" col-id="firm-indication-unit">
                            {ioi.unitType === 'dollars' && 'USD'}
                            {ioi.unitType === 'percentage' && '% of Offering'}
                            {ioi.unitType === 'shares' && 'Shares'}
                          </td>
                          <td className="value-column" col-id="firm-indication-quantity">
                            {ioi.unitType === 'dollars' &&
                              numericUtil.formatCurrencyInMillions(ioi.dollars)}
                            {ioi.unitType === 'percentage' &&
                              numericUtil.formatPercents(ioi.percentage, 2)}
                            {ioi.unitType === 'shares' && numericUtil.formatInteger(ioi.shares)}
                          </td>
                          <td className="value-column" col-id="firm-indication-internal-demand">
                            {ioi.unitType === 'dollars' &&
                              ioi.realDemandDollars &&
                              numericUtil.formatCurrencyInMillions(ioi.realDemandDollars)}
                            {ioi.unitType === 'percentage' &&
                              ioi.realDemandPercentage &&
                              numericUtil.formatPercents(ioi.realDemandPercentage, 2)}
                            {ioi.unitType === 'shares' &&
                              ioi.realDemandShares &&
                              numericUtil.formatInteger(ioi.realDemandShares)}
                          </td>
                        </tr>
                      ) : (
                        <tr key={ioi.uuid}>
                          <td className="value-column" aria-label="Limit Price">
                            <FormControl error={ioisFormErrors[ioi.uuid].pricingType}>
                              <Select
                                isClearable={false}
                                options={ioiPricingTypeOptions}
                                styledConfig={styledConfig({
                                  isInFormGroup: ioi.pricingType === 'limit',
                                })}
                                value={ioi.pricingType}
                                onChange={value => onChangeIoIPricingType(value, ioi.uuid)}
                              />
                            </FormControl>
                          </td>
                          <td className="value-column">
                            {ioi.pricingType === 'limit' && (
                              <div className={cn({ 'inputs-group': ioi.pricingType === 'limit' })}>
                                <FormControl error={ioisFormErrors[ioi.uuid].limitType}>
                                  <Select
                                    isClearable={false}
                                    options={
                                      offeringType !== OfferingType.IPO
                                        ? ioiLimitTypeOptions
                                        : [{ value: IoiLimitType.PRICE, label: 'Price' }]
                                    }
                                    styledConfig={styledConfig({
                                      isInFormGroup: ioi.pricingType === 'limit',
                                    })}
                                    value={ioi.limitType}
                                    onChange={value => onChangeIoILimitType(value, ioi.uuid)}
                                  />
                                </FormControl>
                                <FormControl error={ioisFormErrors[ioi.uuid].limitPrice}>
                                  {ioi.limitType === IoiLimitType.PRICE ? (
                                    <CurrencyInput
                                      placeholder="7.50"
                                      precision={2}
                                      value={ioi.limitPrice}
                                      onChange={value => onChangeIoILimitPrice(value, ioi.uuid)}
                                    />
                                  ) : (
                                    <PercentInput
                                      placeholder="1.0"
                                      precision={2}
                                      value={ioi.limitPercentage}
                                      onChange={value =>
                                        onChangeIoILimitPercentage(value, ioi.uuid)
                                      }
                                    />
                                  )}
                                </FormControl>
                              </div>
                            )}
                          </td>
                          <td className="value-column" aria-label="Unit Type">
                            <FormControl error={ioisFormErrors[ioi.uuid].unitType}>
                              <Select
                                isClearable={false}
                                options={ioiUnitTypeOptions}
                                value={ioi.unitType}
                                onChange={value => onChangeIoIUnitType(value, ioi.uuid)}
                              />
                            </FormControl>
                          </td>
                          <td className="value-column">
                            {ioi.unitType === 'dollars' && (
                              <FormControl error={ioisFormErrors[ioi.uuid].dollars}>
                                <CurrencyInput
                                  aria-label="Quantity"
                                  placeholder="1,000"
                                  precision={2}
                                  value={ioi.dollars}
                                  onChange={value => onChangeIoIDollars(value, ioi.uuid)}
                                />
                              </FormControl>
                            )}

                            {ioi.unitType === 'percentage' && (
                              <FormControl error={ioisFormErrors[ioi.uuid].percentage}>
                                <PercentInput
                                  aria-label="Quantity"
                                  placeholder="1.0"
                                  precision={2}
                                  value={ioi.percentage}
                                  onChange={value => onChangeIoIPercentage(value, ioi.uuid)}
                                />
                              </FormControl>
                            )}

                            {ioi.unitType === 'shares' && (
                              <FormControl error={ioisFormErrors[ioi.uuid].shares}>
                                <NumericInput
                                  aria-label="Quantity"
                                  placeholder="1,000,000"
                                  precision={0}
                                  value={ioi.shares}
                                  onChange={value => onChangeIoIShares(value, ioi.uuid)}
                                />
                              </FormControl>
                            )}
                          </td>

                          <td className="value-column">
                            {ioi.unitType === 'dollars' && (
                              <FormControl error={ioisFormErrors[ioi.uuid].realDemandDollars}>
                                <CurrencyInput
                                  aria-label="Internal Demand"
                                  placeholder="1,000"
                                  precision={2}
                                  value={ioi.realDemandDollars}
                                  onChange={value => onChangeIoIRealDemandDollars(value, ioi.uuid)}
                                />
                              </FormControl>
                            )}

                            {ioi.unitType === 'percentage' && (
                              <FormControl error={ioisFormErrors[ioi.uuid].realDemandPercentage}>
                                <PercentInput
                                  aria-label="Internal Demand"
                                  placeholder="1.0"
                                  precision={2}
                                  value={ioi.realDemandPercentage}
                                  onChange={value =>
                                    onChangeIoIRealDemandPercentage(value, ioi.uuid)
                                  }
                                />
                              </FormControl>
                            )}

                            {ioi.unitType === 'shares' && (
                              <FormControl error={ioisFormErrors[ioi.uuid].realDemandShares}>
                                <NumericInput
                                  aria-label="Internal Demand"
                                  placeholder="1,000,000"
                                  precision={0}
                                  value={ioi.realDemandShares}
                                  onChange={value => onChangeIoIRealDemandShares(value, ioi.uuid)}
                                />
                              </FormControl>
                            )}
                          </td>
                          {canEdit && (
                            <td className="action-column">
                              <TrashButton onClick={() => onClickDeleteIoIButton(ioi.uuid)} />
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </StyledTable>
              )}

              <SButtonWrapper>
                {canEdit && (isEditing || iois.length === 0) && (
                  <LinkButton
                    onClick={onClickAddIoIButton}
                    className="space-right w90"
                    disabled={!allocationEnabled}
                    iconLeft={{ name: 'plus' }}
                    testId={ioiTrackerAddIOISelector.testId}
                  >
                    Add IOI
                  </LinkButton>
                )}

                {canEdit && (
                  <RadioGroup
                    activeId={ioiType}
                    onChange={this.onClickSelectIoIType}
                    canUnselect
                    emptyId=""
                  >
                    <StyledRadioButton
                      id="pass"
                      label="Pass"
                      testId={ioiTrackerPassSelector.testId}
                    />
                    <StyledRadioButton
                      id="reg-m"
                      label="Reg-M"
                      testId={ioiTrackerRegMSelector.testId}
                    />
                  </RadioGroup>
                )}
              </SButtonWrapper>

              <Row className="top-gap">
                <Column xs={24} sm={8}>
                  <FormField label="Final Allocation (Shares)">
                    <NumericInput
                      aria-label="Final Allocation (Shares)"
                      testId={ioiTrackerFinalSharesSelector.testId}
                      disabled={!allocationEnabled || !isEditing}
                      placeholder={
                        !allocationEnabled || !isEditing
                          ? 'N/A'
                          : numericUtil.formatInteger(1000000)
                      }
                      precision={0}
                      value={allocationShares}
                      onChange={this.onChangeAllocationShares}
                    />
                  </FormField>
                </Column>

                {allocationInvestment !== null && allocationInvestment !== undefined && (
                  <Column xs={24} sm={8}>
                    <FormField label="Final Allocation (Dollars)">
                      <SFieldValue>
                        {numericUtil.formatCurrency(allocationInvestment, 1)}
                      </SFieldValue>
                    </FormField>
                  </Column>
                )}

                <Column>
                  <FormField label={' '}>
                    <RankingTag
                      editable={isEditing && allocationEnabled}
                      ranking={allocationRanking}
                      onChangeRanking={this.onChangeRanking}
                    />
                    <div data-test-id={ioiTrackerPassLabelSelector.testId}>
                      <SIoiType>{passTypeLabels[ioiType]}</SIoiType>
                    </div>
                  </FormField>
                </Column>
              </Row>
            </Box.Content>
          </div>
        </Box>

        <AllocationConfirmDialog
          show={showConfirmModal}
          onCancel={this.onCancelAllocationDialog}
          onConfirm={this.onConfirmAllocationDialog}
        />
      </SFirmIndication>
    );
  }
}
