import {
  Card,
  Grid,
  RegularBreakpoints,
  Section,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@cmg/design-system';
import React from 'react';

import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { useGetManagersSectionData } from '../hooks/useGetManagersSectionData';
import { cardHeight } from '../OfferingProfileCardItem';
import { ManagerRowItem } from '../types';
import {
  StyledTableCell,
  StyledTableContainer,
  StyledTableHeader,
} from './ManagersWidgetV2.styles';

export type ManagerTableProps = {
  managers: ManagerRowItem[];
};
export type Props = {
  offeringProfile: OfferingProfile_ListQuery;
  cardSizes?: RegularBreakpoints;
  id?: string;
};

const ManagerTable: React.FC<ManagerTableProps> = ({ managers }) => {
  return (
    <Table size="medium">
      <TableHead>
        <TableRow color="text.secondary">
          <TableCell></TableCell>
          <TableCell>Role</TableCell>
          <StyledTableHeader align="right">Base Securities</StyledTableHeader>
          <StyledTableHeader align="right">Economics %</StyledTableHeader>
          <StyledTableHeader align="right">Estimated Fee</StyledTableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {managers.map(
          ({ name, role, underwritingBaseShares, pctUnderwritingBaseShares, estimatedFee }) => {
            return (
              <TableRow key={name}>
                <StyledTableCell width="30%">{name}</StyledTableCell>
                <StyledTableCell width="25%">{role}</StyledTableCell>
                <StyledTableCell align="right" width="15%">
                  {underwritingBaseShares}
                </StyledTableCell>
                <StyledTableCell align="right" width="15%">
                  {pctUnderwritingBaseShares}
                </StyledTableCell>
                <StyledTableCell align="right" width="15%">
                  {estimatedFee}
                </StyledTableCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </Table>
  );
};

const ManagersWidget: React.FC<Props> = ({ offeringProfile, cardSizes, id }) => {
  const { bookrunners, nonBookrunners, totalManagers, totalBookrunners, totalNonBookrunners } =
    useGetManagersSectionData(offeringProfile);
  const title = React.useMemo(() => `Managers (${totalManagers ?? 0})`, [totalManagers]);
  return (
    <Grid item {...cardSizes}>
      <Card elevation={0} sx={cardHeight} id={id}>
        <Section title={title} aria-label={title} paddingTop={theme => theme.spacing(3)}>
          {!totalManagers || totalManagers === 0 ? (
            <Typography color="text.secondary">No Data Available</Typography>
          ) : (
            <StyledTableContainer>
              <Grid container spacing={3} display="flex">
                {bookrunners && bookrunners.length > 0 && (
                  <Grid item minWidth={theme => theme.spacing(68)} flex={1}>
                    <Typography variant="highlight1">{`Bookrunners (${totalBookrunners})`}</Typography>
                    <ManagerTable managers={bookrunners} />
                  </Grid>
                )}
                {nonBookrunners && nonBookrunners.length > 0 && (
                  <Grid item minWidth={theme => theme.spacing(68)} flex={1}>
                    <Typography variant="highlight1">
                      {`Non-Bookrunners (${totalNonBookrunners})`}
                    </Typography>
                    <ManagerTable managers={nonBookrunners} />
                  </Grid>
                )}
              </Grid>
            </StyledTableContainer>
          )}
        </Section>
      </Card>
    </Grid>
  );
};

export default ManagersWidget;
